import React from "react";
import { Datepicker } from "./SearchDatePicker";

export default class DatepickerStandalone extends Datepicker
{
  constructor(props) {
    super(props);
  }
 

  search() {
    let formValue = this.state.bookingFormElement.bookingForm.getValue();
    formValue.push({name: 'city', value: this.state.formData.city});

    const baseUrl = process.env.REACT_APP_SITE_URL;
    const url = new URL(baseUrl);

    url.searchParams.set('checkIn', formValue[0].value);
    url.searchParams.set('checkOut', formValue[1].value);
    url.searchParams.set('adults', formValue[2].value);
    url.searchParams.set('children', formValue[3].value);
    url.searchParams.set('city', formValue[4].value);
    url.searchParams.set('isMonth', this.props.isMonth)

    this.saveFormData(formValue);

    window.location.href = url.href;
  }
  render() {
    return (
        <Datepicker 
            {...this.props}
            search={this.search}
        />
    );
  }
}