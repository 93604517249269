import './SearchFormApp.scss';
import React, { useState } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import DatepickerStandalone from './components/Forms/Datepicker/DatepickerStandalone';

function SearchFormApp() {
    const queryParameters = new URLSearchParams(window.location.search);
    const [selectedTab, setSelectedTab] = useState(queryParameters.get('isMonth') === "true" ? 2 : 1);

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <div className='tabsDate'>
                            <div className='tabs'>
                                <div 
                                    className={selectedTab === 1 ? "tab-title-active" : 'tab-title'} 
                                    onClick={() => setSelectedTab(1)}>
                                        VACATION RENTALS
                                </div>
                                <div 
                                    className={selectedTab === 2 ? "tab-title-active" : 'tab-title'} 
                                    onClick={() => setSelectedTab(2)}>
                                    LONG-TERM RENTALS
                                </div>
                            </div>
                            <DatepickerStandalone
                                isMonth={selectedTab === 2}
                                updateBookingFormEl = {() => {}}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default SearchFormApp;
